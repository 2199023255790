import React from 'react'
import { Resource, defaultTheme } from 'react-admin'
import RunList from './components/resources/runs/RunList'
import RunShow from './components/resources/runs/RunShow'
import { TenantShowWrapper } from './components/resources/tenants/TenantShow'
import TenantList from './components/resources/tenants/List/TenantList'
import TenantEdit from './components/resources/tenants/Edit/TenantEdit'
import TenantCreate from './components/resources/tenants/TenantCreate'
import IntegrationList from './components/resources/integrations/IntegrationList'
import IntegrationCreateWrapper from './components/resources/integrations/Create/IntegrationCreateWrapper'
import IntegrationEditWrapper from './components/resources/integrations/Edit/IntegrationEditWrapper'
import IntegrationShow from './components/resources/integrations/IntegrationShow'
import UserShow from './components/resources/user/UserShow/UserShow'
import UserList from './components/resources/user/UserList'
import PartnerList from './components/resources/partners/List/PartnerList'
import PartnerShow from './components/resources/partners/Show/PartnerShow'
import PartnerEdit from './components/resources/partners/Edit/PartnerEdit'
import PartnerCreate from './components/resources/partners/Create/PartnerCreate'
import AccountShow from './components/resources/accounts/AccountShow'
import { createTheme } from '@mui/material/styles'
import RerunConfiguration from './components/resources/runs/Rerun/RerunConfiguration'
export const TENANTS_PER_PAGE = 50
export const RUNS_PER_PAGE = 50
export const PARTNERS_PER_PAGE = 50
export const LABELS_PER_PAGE = 20
export const SUBGRID_RECORDS_PER_PAGE = 50
export const API_MAX_PER_PAGE = 500

export const keycloakURIFromHost = () => {
    const ourHostName = window.location.hostname
    const baseHost = ourHostName.split('.').slice(1).join('.')

    if (window.location !== window.parent.location) {
        // The page is in an iFrames
        return `https://authz.nc.${baseHost}/auth`
    } else {
        return `https://authz.${baseHost}/auth`
    }
}

export const apiURIFromHost = () => {
    const ourHostName = window.location.hostname
    const baseHost = ourHostName.split('.').slice(1).join('.')

    return `https://api.${baseHost}/v1`
}

export const KeycloakConfig = {
    realm: process.env.REACT_APP_OVERRIDE_REALM || 'admin-dash',
    url: process.env.REACT_APP_OVERRIDE_KEYCLOAK_URI || keycloakURIFromHost(),
    clientId: process.env.REACT_APP_OVERRIDE_CLIENT_ID || 'admin-dash',
}
export const NO_COOKIE = KeycloakConfig.url.includes('.nc.').toString()
export const API_URI = process.env.REACT_APP_API_URI || apiURIFromHost()

export const HEADERS = (token) => {
    return {
        'no-cookie': NO_COOKIE,
        Admin: true,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
    }
}

export const fetchOrganization = async (id) => {
    return fetch(`${API_URI}/organizations/${id}`, {
        method: 'GET',
        headers: HEADERS(sessionStorage.getItem('token')),
    }).then((response) => {
        return response.json()
    })
}

export const fetchThemeConfigs = async (orgId) => {
    return fetch(`${API_URI}/themeconfigs?account_id=${orgId}`, {
        method: 'GET',
        headers: HEADERS(sessionStorage.getItem('token')),
    }).then((response) => {
        return response.json()
    })
}

//Google Analytics
export const GA_DEBUG = false
export const GA_CODE =
    process.env.NODE_ENV === 'production' ? 'UA-107942336-1' : ''

export const Resources = (
    namespace,
    isParentOrg,
    parentOrgName,
    orgApprovalStatus,
    userId
) => {
    const resources = [
        <Resource
            key="integrations"
            name="integrations"
            list={IntegrationList}
            create={IntegrationCreateWrapper}
            edit={() => <IntegrationEditWrapper namespace={namespace} />}
            show={() => <IntegrationShow namespace={namespace} />}
        />,
        <Resource
            key="tenants"
            name="tenants"
            list={() => <TenantList namespace={namespace} />}
            show={TenantShowWrapper}
            edit={() => <TenantEdit namespace={namespace} />}
            create={() => <TenantCreate />}
        />,
        <Resource
            key="runs"
            name="runs"
            list={isParentOrg && RunList}
            show={() => <RunShow namespace={namespace} />}
            edit={() => <RerunConfiguration namespace={namespace} />}
        />,
        <Resource key="integrationreleases" name="integrationreleases" />,
        <Resource key="connectors" name="connectors" />,
        <Resource key="integrationlabels" name="integrationlabels" />,
        <Resource
            key="users"
            name="users"
            show={UserShow}
            list={() => <UserList namespace={namespace} userId={userId} />}
        />,
        <Resource key="groups" name="groups" />,
        <Resource key="themeconfigs" name="themeconfigs" />,
    ]
    const accountResources = [
        <Resource
            key="partners"
            name="partners"
            list={() => <PartnerList namespace={namespace} />}
            create={() => <PartnerCreate namespace={namespace} />}
            edit={() => <PartnerEdit namespace={namespace} />}
            show={() => <PartnerShow userId={userId} namespace={namespace} />}
        />,
    ]

    const orgResource = [
        <Resource
            key="organizations"
            name="organizations"
            edit={() => <AccountShow namespace={namespace} userId={userId} />}
            show={() => <AccountShow namespace={namespace} userId={userId} />}
        />,
    ]

    return orgApprovalStatus === 'Pending'
        ? orgResource
        : isParentOrg
        ? [...resources, ...accountResources, ...orgResource]
        : [...resources, ...orgResource]
}

const PandiumColors = {
    black: '#1d1f24',
    blue: '#79ccff',
    grey: '#9e9e9e',
    purple: '#9d74f9',
    white: '#ffffff',
    bluepurple: '#626FFC',
    red: '#ff4c4c',
}

const appTheme = () => {
    let theme = {
        componentOverride: {
            MuiDivider: {
                root: {
                    '&.pageHead': {
                        marginLeft: '-24px',
                        marginRight: '-24px',
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    root: {
                        '& .MuiDialog-paper': {
                            maxWidth: '600px',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        '&.filledButton': {
                            backgroundColor: PandiumColors.bluepurple,
                            color: 'white',
                            letterSpacing: '2px',
                            textAlign: 'center',
                            lineHeight: '21px',
                            borderRadius: '4',
                            height: '42px',
                            fontSize: '16px',
                            '&:disabled': {
                                backgroundColor: 'grey',
                            },
                        },
                        '&.disabledFilledButton': {
                            backgroundColor: 'grey',
                            color: 'white',
                            letterSpacing: '2px',
                            textAlign: 'center',
                            lineHeight: '21px',
                            borderRadius: '0',
                            height: '42px',
                        },
                        '&.clearButton': {
                            letterSpacing: '2px',
                            textAlign: 'center',
                            lineHeight: '21px',
                            borderRadius: '0',
                            height: '42px',
                        },
                        color: PandiumColors.bluepurple,
                        fontFamily: 'RobotoCondensedBold',
                        fontSize: '15px',
                        letterSpacing: '2px',
                    },
                },
                variants: [
                    {
                        props: { variant: 'outlined' },
                        style: {
                            color: PandiumColors.bluepurple,
                            fontFamily: 'RobotoCondensedBold',
                            fontSize: '15px',
                            letterSpacing: '2px',
                            borderWidth: 1.5,
                            borderColor: PandiumColors.bluepurple,
                            '&:hover:hover': {
                                borderWidth: 1.5,
                                color: 'white',
                                borderColor: PandiumColors.bluepurple,
                                backgroundColor: PandiumColors.bluepurple,
                            },
                        },
                    },
                ],
            },
            MuiTableRow: {
                root: {
                    '&:hover:hover': {
                        '& td': {
                            '& .detailLink': {
                                color: PandiumColors.bluepurple,
                                letterSpacing: '1.5px',
                                fontFamily: 'RobotoCondensedBold',
                                textDecoration: 'none',
                            },
                        },
                    },
                    '& td': {
                        '& .detailLink': {
                            color: 'white',
                            letterSpacing: '1.5px',
                            fontFamily: 'RobotoCondensedBold',
                            textDecoration: 'none',
                        },
                    },
                },
            },
            MuiSvgIcon: {
                root: {
                    verticalAlign: 'middle',
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    switchBase: {
                        color: 'white',
                        '&.Mui-checked': {
                            color: '#626FFC !important',
                        },
                        '&.Mui-checked + $track': {
                            color: '#626FFC !important',
                        },
                    },
                    track: {
                        backgroundColor: '#626FFC !important',
                    },
                },
            },
        },
        // TODO: can we use this functionality to eliminate some
        // per-component styling?
        palette: {
            primary: {
                main: PandiumColors.black,
            },
            secondary: {
                main: PandiumColors.black,
            },
            type: 'light',
            background: {
                default: PandiumColors.black,
            },
            error: { main: PandiumColors.red },
        },
    }

    return {
        // Default sidebar values
        ...defaultTheme,
        sidebar: {
            width: 250,
            closedWidth: 60,
        },
        components: {
            ...defaultTheme.components,
            ...theme.componentOverride,
        },
        palette: {
            ...theme.palette,
        },
        PandiumColors: PandiumColors,
        typography: {
            useNextVariants: true,
            fontFamily: 'Roboto Condensed, sans-serif',
            fontSize: 15,
        },
    }
}

export const muiV5Theme = createTheme(appTheme())
