import React, { useState } from 'react'
import { useDataProvider, useNotify, useRedirect } from 'react-admin'
import { useNavigate } from 'react-router-dom'
import {
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    RadioGroup,
    Radio,
    FormControlLabel,
    Tooltip,
    Box,
} from '@mui/material'

export default ({
    open,
    setOpen,
    runId,
    tenantId,
    persistStdoutOnly = false,
    rerunCustomConfiguration,
}) => {
    const dataProvider = useDataProvider()
    const redirect = useRedirect()
    const navigate = useNavigate()
    const notify = useNotify()
    const [rerunType, setRerunType] = useState(
        persistStdoutOnly ? 'custom' : 'original'
    )
    const [page, setPage] = useState(persistStdoutOnly ? 1 : 0)
    const [persistStdout, setPersistStdout] = useState(false)

    const close = () => {
        setOpen(false)
        if (!persistStdoutOnly) {
            setPage(0)
        }
    }

    const next = () => {
        if (rerunType === 'custom') {
            redirect('/runs/' + runId + '/edit')
        } else {
            setPage(1)
        }
    }

    const rerun = () => {
        close()
        const custom = rerunCustomConfiguration
            ? rerunCustomConfiguration()
            : {}
        const payload = {
            rerunType: rerunType,
            ...custom,
        }
        if (!persistStdout) {
            payload.persistStdout = false
        }

        console.log('RERUNNING WITH')
        console.log(JSON.stringify(payload))
        dataProvider
            .RERUN_INITIATED('runs', {
                runId: runId,
                data: payload,
            })
            .then((res) => {
                if (res.data) {
                    notify('Rerunning')
                    if (tenantId) {
                        redirect(`/tenants/${tenantId}/show`)
                    } else {
                        navigate(-1)
                    }
                }
            })
            .catch((err) => {
                notify('Error creating run', { type: 'error' })
                console.log(
                    `Error initiating rerun for run ID ${runId}: ${err}`
                )
            })
    }

    return (
        <Dialog
            open={open}
            onClose={close}
            maxWidth={'sm'}
            fullWidth={true}
            sx={{ padding: '20px' }}
        >
            <DialogTitle sx={{ fontWeight: 'semibold' }}>
                Are you sure you want to rerun this run?
            </DialogTitle>
            <DialogContent>
                {page === 0 ? (
                    <>
                        <Typography sx={{ mb: 2 }}>
                            How would you like to configure this rerun?
                        </Typography>
                        <RadioGroup
                            sx={{ ml: 1 }}
                            value={rerunType}
                            onChange={(e) => {
                                setRerunType(e.target.value)
                            }}
                        >
                            <FormControlLabel
                                value={'original'}
                                control={<Radio />}
                                label="Rerun with original configuration"
                            />
                            <FormControlLabel
                                value={'current'}
                                control={<Radio />}
                                label="Rerun with current configuration of tenant"
                            />
                            <FormControlLabel
                                value={'custom'}
                                control={<Radio />}
                                label="Rerun with custom configuration"
                            />
                        </RadioGroup>
                    </>
                ) : (
                    <>
                        <Typography sx={{ mb: 2 }}>
                            Do you want to save the STDOUT from this rerun to be
                            used in future runs?
                        </Typography>
                        <RadioGroup
                            sx={{ ml: 1, mb: 2 }}
                            value={persistStdout}
                            onChange={(e) => {
                                setPersistStdout(e.target.value)
                            }}
                        >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Yes"
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="No (default)"
                            />
                        </RadioGroup>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Box>
                    {page === 0 || persistStdoutOnly ? (
                        <Button onClick={close}>Cancel</Button>
                    ) : (
                        <Button onClick={() => setPage(0)}>Back</Button>
                    )}
                    <Button onClick={page === 0 ? next : rerun}>
                        {page === 0 ? 'Next' : 'Rerun'}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}
