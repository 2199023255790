import React, { useEffect, useState } from 'react'
import { LogField } from '../../LogField'
import { SimpleShowLayout, useGetOne, Link, useDataProvider } from 'react-admin'
import { get } from 'lodash-es'
import {
    Card,
    Button,
    LinearProgress,
    Box,
    Typography,
    Tooltip,
} from '@mui/material'
import { HorizontalTextField, RunPhaseField } from '../../common'
import { format } from 'date-fns'
import BaseShow from '../Show/BaseShow'
import { useParams, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import StdoutField from '../../StdoutField'
import BaseTopToolbar from '../../react_admin/BaseTopToolbar'
import RerunDialog from '../../RerunDialog'

const RunTopToolbar = ({
    tenantConnected,
    runPhase,
    runId,
    tenantId,
    runMode,
}) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    return (
        <BaseTopToolbar
            pageTitle={'Run Detail'}
            handleBack={() => navigate(-1)}
            backButtonText="Back"
        >
            <Tooltip
                title={
                    runMode === 'rerun' &&
                    'Go to the original run to start a rerun'
                }
            >
                <span>
                    <Button
                        disabled={
                            !tenantConnected ||
                            !(
                                runPhase?.includes('Failed') ||
                                runPhase === 'Succeeded'
                            ) ||
                            runMode === 'rerun'
                        }
                        onClick={() => setOpen(true)}
                        variant="contained"
                        sx={{
                            fontFamily: 'RobotoCondensedBold',
                            fontSize: '15px',
                            color: '#FFFFFF',
                            letterSpacing: '2px',
                            textAlign: 'center',
                            lineHeight: '21px',
                            width: '165px',
                            height: '42px',
                            marginY: '15px',
                            marginRight: '7px',
                            backgroundColor: '#353DDF',
                            borderRadius: '0',
                            '&:hover:hover': {
                                backgroundColor: '#353DDF',
                            },
                        }}
                    >
                        RERUN
                    </Button>
                </span>
            </Tooltip>
            <RerunDialog
                open={open}
                setOpen={setOpen}
                runId={runId}
                tenantId={tenantId}
            />
        </BaseTopToolbar>
    )
}

const generalCardStyles = {
    valueText: {
        fontFamily: 'RobotoCondensedBold',
        fontSize: '20px',
        paddingBottom: '5px',
    },
    fieldText: {
        color: '#6d6d6d',
        fontSize: '20px',
        marginBottom: '10px',
    },
    flex1: {
        flex: '1',
        padding: '3px',
    },
    flex2: {
        flex: '0',
        flexBasis: '12%',
        padding: '3px',
    },
    firstCard: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
        boxShadow: 'none',
        flex: '1',
        borderRadius: '0',
        margin: '0px 0 30px 0',
        padding: '10px 50px 10px 50px',
    },
}

const GeneralCard = ({ record, userId, tenantId, isSuperUser }) => {
    // very specific line of code in order for users to get a link to the integration being built by source control
    const integrationBuilt =
        record.jobSpec?.integration?.name.includes('source-control') &&
        !['init', 'webhook'].includes(record.mode) &&
        record?.jobSpec?.tenant?.configs?.force_build_id

    return (
        <Card sx={generalCardStyles.firstCard}>
            <Box sx={generalCardStyles.flex1}>
                <Box sx={generalCardStyles.fieldText}>ID</Box>
                <Box sx={generalCardStyles.valueText}>{record.id}</Box>
            </Box>
            {(isSuperUser ||
                record?.jobSpec?.integration?.type !== 'Pandium') && (
                <Box sx={generalCardStyles.flex1}>
                    <Box sx={generalCardStyles.fieldText}>{'TENANT'}</Box>
                    <Link
                        to={'/tenants/' + tenantId + '/show'}
                        sx={generalCardStyles.valueText}
                    >
                        {userId ? userId : tenantId}
                    </Link>
                </Box>
            )}
            {(isSuperUser ||
                record.jobSpec?.integration?.type !== 'Pandium') && (
                <Box sx={generalCardStyles.flex1}>
                    <Box sx={generalCardStyles.fieldText}>INTEGRATION</Box>
                    <Link
                        to={`/integrations/${record?.integrationId}/show`}
                        sx={generalCardStyles.valueText}
                    >
                        {record?.jobSpec?.integration?.name}
                    </Link>
                </Box>
            )}
            {integrationBuilt && (
                <Box sx={generalCardStyles.flex1}>
                    <Box sx={generalCardStyles.fieldText}>
                        INTEGRATION BUILT
                    </Box>
                    <Link
                        to={`/integrations/${integrationBuilt}/show`}
                        sx={generalCardStyles.valueText}
                    >
                        {record?.jobSpec?.tenant?.configs?.force_build}
                    </Link>
                </Box>
            )}
            <Box sx={generalCardStyles.flex2}>
                <Box sx={generalCardStyles.fieldText}>MODE</Box>
                {record.mode === 'rerun' &&
                record.triggers?.[0]?.payload?.rerun.run_id ? (
                    <Link
                        to={`/runs/${record.triggers?.[0]?.payload?.rerun.run_id}/show`}
                        sx={generalCardStyles.valueText}
                    >
                        {record.mode}
                    </Link>
                ) : (
                    <Box sx={generalCardStyles.valueText}>{record.mode}</Box>
                )}
            </Box>
            <Box sx={generalCardStyles.flex2}>
                <Box sx={generalCardStyles.fieldText}>STATUS</Box>
                <Box sx={generalCardStyles.valueText}>
                    <RunPhaseField record={record} source="status.phase" />{' '}
                </Box>
            </Box>
        </Card>
    )
}

const configsStyles = {
    configurationCard: {
        borderBottom: '1px solid #D8D8D8',
        borderTop: '1px solid #D8D8D8',
        boxShadow: 'none',
        flex: '1',
        borderRadius: '0',
        padding: '36.5px 50px 25.5px 50px',
        display: 'flex',
        margin: '0px 0 30px 0',
        '& > div': {
            width: '100%',
        },
    },
    fieldText: {
        color: '#6d6d6d',
        fontSize: '20px',
        marginBottom: '10px',
    },
}

const ConfigsAndDetails = ({ configs, status, details }) => {
    return (
        <Card sx={configsStyles.configurationCard}>
            <div>
                <Typography sx={configsStyles.fieldText}>
                    CONFIGURATION
                </Typography>
                {Object.keys(configs).map((key) => (
                    <HorizontalTextField
                        key={key}
                        record={configs}
                        source={key}
                        label={false}
                    />
                ))}
            </div>
            <div>
                <Typography sx={configsStyles.fieldText}>DETAILS</Typography>
                {Object.keys(details).map((key) => (
                    <HorizontalTextField
                        key={key}
                        record={details}
                        source={key}
                        label={false}
                    />
                ))}
                <Box sx={{ height: '6px' }} />
                <StdoutField
                    filepath={status?.last_run?.stdOut}
                    runType={'last_run'}
                    label={'Last Run STDOUT'}
                />
                <StdoutField
                    filepath={status?.last_successful_run?.stdOut}
                    runType={'last_successful_run'}
                    label={'Last Successful Run STDOUT'}
                />
            </div>
        </Card>
    )
}

const RunShow = ({ namespace, isSuperUser }) => {
    const dataProvider = useDataProvider()
    const [tenant, setTenant] = useState()
    const { id } = useParams()
    const { data: run, runLoading } = useGetOne('runs', { id: id })

    useEffect(() => {
        const getTenant = async () => {
            const res = await dataProvider.getOne('tenants', {
                id: run.tenantId,
            })
            if (res) setTenant(res.data)
        }
        if (run) getTenant()
    }, [run, dataProvider])

    if (!run || runLoading) {
        return <LinearProgress />
    }

    const tenantFromJobSpec = run.jobSpec?.tenant
    const details = {
        Start: run?.startedDate
            ? format(new Date(run.startedDate), 'M/dd/yyyy, h:mm:ss aa')
            : 'n/a',
        Finish: run?.completedDate
            ? format(new Date(run.completedDate), 'M/dd/yyyy, h:mm:ss aa')
            : 'in progress',
        'Integration Release': run.jobSpec?.integration_release?.tag,
    }

    return (
        <BaseShow
            actions={null}
            TopToolbar={
                <RunTopToolbar
                    tenantConnected={tenant?.status?.auth?.connected}
                    runPhase={run.status?.phase}
                    runId={id}
                    tenantId={run.jobSpec?.tenant.id}
                    runMode={run.mode}
                />
            }
        >
            <SimpleShowLayout>
                <GeneralCard
                    isSuperUser={isSuperUser}
                    record={run}
                    userId={get(tenantFromJobSpec, 'name')}
                    tenantId={get(tenantFromJobSpec, 'id')}
                />
                {tenantFromJobSpec && (
                    <ConfigsAndDetails
                        configs={tenantFromJobSpec?.configs}
                        status={tenantFromJobSpec?.status}
                        details={details}
                    />
                )}
                {
                    <LogField
                        record={run}
                        source="id"
                        namespace={namespace}
                        label={false}
                    />
                }
            </SimpleShowLayout>
        </BaseShow>
    )
}

const mapStateToProps = (state) => ({
    isSuperUser: state.org.isSuperUser,
})

export default connect(mapStateToProps)(RunShow)
