import { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { JsonFormsWrapper } from '../../../jsonFormComponents/JsonFormsWrapper'
import { useGetOne, Edit, useDataProvider } from 'react-admin'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty, get } from 'lodash-es'
import { PageTitle, SelectReleasesInput } from '../../../common'
import { Box, Button, Divider, Typography } from '@mui/material'
import { getDynamicConfigSchema } from '@thefront/pandipackV2'
import { KeyboardArrowLeft, ExpandMore, ExpandLess } from '@mui/icons-material'
import { API_URI, HEADERS } from '../../../../appConfigs'
import CustomFileEditor from '../../../CustomFileEditor'
import RerunDialog from '../../../RerunDialog'

const CollapsibleFormSection = ({ title, children }) => {
    const [expanded, setExpanded] = useState(false)
    return (
        <Box
            sx={{
                backgroundColor: '#F5F5F5',
                borderRadius: 3,
                mb: 2,
            }}
        >
            <Button
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    textTransform: 'none',
                    borderRadius: 3,
                }}
                onClick={() => setExpanded(!expanded)}
            >
                <Typography
                    sx={{
                        color: 'black',
                        fontSize: '24px',
                        letterSpacing: 0.8,
                        ml: 1,
                    }}
                >
                    {title}
                </Typography>
                {expanded ? (
                    <ExpandLess sx={{ color: 'black' }} fontSize="large" />
                ) : (
                    <ExpandMore sx={{ color: 'black' }} fontSize="large" />
                )}
            </Button>
            {expanded && <Box sx={{ px: 2, py: 1 }}>{children}</Box>}
        </Box>
    )
}

const EditSettings = ({
    pageTitle,
    resource,
    primaryResource,
    dynamicConfigs,
    showStdoutSection,
    stdoutFilepath,
    onCancel,
    submitText,
    onSubmit,
}) => {
    const dataProvider = useDataProvider()

    const [serverText, setServerText] = useState()
    const [editedText, setEditedText] = useState()
    const [isUploading, setIsUploading] = useState(false)

    const [
        selectedIntegrationReleaseId,
        setSelectedIntegrationReleaseId,
    ] = useState(primaryResource.integrationRelease.id)
    const [schema, setSchema] = useState()
    const [uischema, setUiSchema] = useState({})

    const [jsonFormValues, setJsonFormValues] = useState(
        get(primaryResource, 'configs', {})
    )

    // Call back to get state from child
    const onJsonFormChange = (errors, jsonFormValues) => {
        setJsonFormValues(jsonFormValues)
        methods.setValue('configs', jsonFormValues)
    }

    const methods = useForm({
        defaultValues: primaryResource,
        mode: 'onBlur',
    })

    useEffect(() => {
        const getIntegrationRelease = async () => {
            const result = await dataProvider.getOne('integrationreleases', {
                id: selectedIntegrationReleaseId,
            })
            const intRel = result.data
            setSchema(
                getDynamicConfigSchema(
                    intRel.configSchema.schema,
                    dynamicConfigs
                )
            )
            setUiSchema(intRel.configSchema.uischema)
        }

        if (
            selectedIntegrationReleaseId &&
            selectedIntegrationReleaseId !== -1
        ) {
            getIntegrationRelease()
        }
    }, [selectedIntegrationReleaseId, dynamicConfigs, dataProvider])

    useEffect(() => {
        const getStdout = async () => {
            try {
                const response = await fetch(
                    `${API_URI}/files/${stdoutFilepath}`,
                    {
                        method: 'GET',
                        headers: HEADERS(sessionStorage.getItem('token')),
                    }
                )
                if (response.ok) {
                    const text = await response.text()
                    let formatted
                    try {
                        formatted = JSON.stringify(JSON.parse(text), null, '\t')
                    } catch (e) {
                        formatted = text
                    }
                    setServerText(formatted)
                    setEditedText(formatted)
                }
            } catch (error) {
                console.error(
                    `Error fetching STDOUT for filepath ${stdoutFilepath}: ${error}`
                )
            }
        }
        if (showStdoutSection && stdoutFilepath) getStdout()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showStdoutSection, stdoutFilepath])

    const onEditorChange = (newValue) => {
        setEditedText(newValue)
        methods.setValue('stdout', newValue)
    }

    return (
        <>
            <PageTitle title={pageTitle} />
            <Divider className="pageHead" />
            <Edit actions={null} component="div">
                <Box sx={{ maxWidth: '800px' }}>
                    <FormProvider {...methods}>
                        <CollapsibleFormSection title={'Release'}>
                            <SelectReleasesInput
                                source={'integrationRelease.id'}
                                onChange={(e) => {
                                    setSelectedIntegrationReleaseId(
                                        e.target.value
                                    )
                                }}
                            />
                        </CollapsibleFormSection>
                        {showStdoutSection && (
                            <CollapsibleFormSection title={'STDOUT'}>
                                <Typography sx={{ mb: 1 }}>
                                    Modify what will be passed into the
                                    environment variables
                                    PAN_CTX_LAST_SUCCESSFUL_RUN_STD_OUT and
                                    PAN_CTX_LAST_RUN_STD_OUT.
                                </Typography>
                                <CustomFileEditor
                                    mode="text"
                                    serverText={serverText}
                                    editedText={editedText}
                                    setEditedText={setEditedText}
                                    isUploading={isUploading}
                                    setIsUploading={setIsUploading}
                                    onEditorChange={onEditorChange}
                                    filenamePrefix={'pandium_edited_stdout'}
                                    fileType="text"
                                />
                            </CollapsibleFormSection>
                        )}
                        <CollapsibleFormSection title={'Configuration'}>
                            {!isEmpty(schema) && typeof schema !== 'string' ? (
                                <JsonFormsWrapper
                                    onJsonFormChange={onJsonFormChange}
                                    schema={schema}
                                    uischema={uischema}
                                    data={jsonFormValues}
                                    dynamicConfigs={dynamicConfigs}
                                />
                            ) : (
                                <div>
                                    {' '}
                                    This integration release has no
                                    configurations set up or there is an issue
                                    with your recent context. <br />
                                    {schema}
                                </div>
                            )}
                        </CollapsibleFormSection>
                        <Box
                            sx={{
                                mt: 4,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Button onClick={onCancel}>CANCEL</Button>
                            {resource === 'runs' && (
                                <Button
                                    variant={'text'}
                                    className={'filledButton'}
                                    sx={{
                                        width: '165px',
                                        height: '42px',
                                        margin: '0 5px 0 5px',
                                        '&:hover': {
                                            backgroundColor: '#353DDF',
                                        },
                                    }}
                                    onClick={() =>
                                        onSubmit(methods.getValues())
                                    }
                                >
                                    {submitText}
                                </Button>
                            )}
                        </Box>
                    </FormProvider>
                </Box>
            </Edit>
        </>
    )
}

export default () => {
    const { id: runId } = useParams()
    const navigate = useNavigate()
    const [dialogOpen, setDialogOpen] = useState(false)
    const [submittedFormValues, setSubmittedFormValues] = useState()

    const { data: run, isLoading: runLoading } = useGetOne('runs', {
        id: runId,
    })

    const rerunCustomConfiguration = () => {
        const rerunConfiguration = {}
        if (
            run.jobSpec.integration_release.id !==
            submittedFormValues.integrationRelease.id
        ) {
            rerunConfiguration.integrationRelease =
                submittedFormValues.integrationRelease.id
        }
        if (submittedFormValues.stdout) {
            let unformatted
            try {
                unformatted = JSON.stringify(
                    JSON.parse(submittedFormValues.stdout)
                )
            } catch {
                unformatted = submittedFormValues.stdout
            }
            rerunConfiguration.stdout = unformatted
        }
        if (
            submittedFormValues.configs !== run.jobSpec.tenant.configs &&
            Object.keys(submittedFormValues.configs ?? {}).length
        ) {
            rerunConfiguration.configs = submittedFormValues.configs
        }
        return rerunConfiguration
    }

    return (
        !runLoading && (
            <Box>
                <Button
                    onClick={() => navigate(-1)}
                    sx={{
                        fontFamily: 'RobotoCondensedBold',
                        color: '#3664E7',
                        fontSize: '15px',
                        letterSpacing: '2px',
                        textAlign: 'center',
                        lineHeight: '14px',
                    }}
                >
                    <KeyboardArrowLeft />
                    Back
                </Button>
                <EditSettings
                    pageTitle={'Configure Run'}
                    resource={'runs'}
                    primaryResource={{
                        configs: run.jobSpec.configs,
                        stdout: null,
                        integrationRelease: run.jobSpec.integration_release,
                    }}
                    dynamicConfigs={
                        run.jobSpec.tenant.status?.dynamic_configs ?? {}
                    }
                    showStdoutSection={true}
                    stdoutFilepath={
                        run.jobSpec.tenant.status?.last_successful_run?.stdOut
                    }
                    onCancel={() => navigate(-1)}
                    submitText={'RERUN'}
                    onSubmit={(formValues) => {
                        setDialogOpen(true)
                        setSubmittedFormValues(formValues)
                    }}
                />
                <RerunDialog
                    open={dialogOpen}
                    setOpen={setDialogOpen}
                    runId={runId}
                    tenantId={run.tenantId}
                    persistStdoutOnly={true}
                    rerunCustomConfiguration={rerunCustomConfiguration}
                />
            </Box>
        )
    )
}
