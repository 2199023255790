import React from 'react'
import AceEditor from 'react-ace'
import 'brace/mode/css'
import 'brace/theme/cobalt'
import { Box, Button, Typography } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CloseIcon from '@mui/icons-material/Close'
import { useDropzone } from 'react-dropzone'

const FileUploader = ({ setEditedText, setIsUploading }) => {
    const onFileChange = (files) => {
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            const content = fileReader.result
            setEditedText(content)
            setIsUploading(false)
        }
        fileReader.readAsText(files[0])
    }
    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: onFileChange,
        noClick: true,
    })

    return (
        <section>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Box
                    sx={{
                        width: '100%',
                        height: '500px',
                        backgroundColor: 'lightgray',
                        position: 'relative',
                    }}
                >
                    <Box
                        width={'100%'}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'absolute',
                            zIndex: 2,
                        }}
                    >
                        <Button
                            onClick={() => setIsUploading(false)}
                            sx={{
                                color: 'black',
                                alignSelf: 'flex-end',
                            }}
                        >
                            <CloseIcon fontSize={'medium'} />
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '10px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '500px',
                            width: '100%',
                            position: 'absolute',
                            zIndex: 1,
                        }}
                    >
                        <CloudUploadIcon fontSize={'large'} />
                        <Button
                            sx={{
                                backgroundColor: 'white',
                                px: 2,
                                '&:hover:hover': {
                                    color: 'white',
                                    backgroundColor: '#626FFC',
                                },
                            }}
                            onClick={open}
                        >
                            Choose a file to upload
                        </Button>
                        <Typography>Or drag and drop it here</Typography>
                    </Box>
                </Box>
            </div>
        </section>
    )
}

export default ({
    mode = 'text',
    serverText,
    editedText,
    setEditedText,
    isUploading,
    setIsUploading,
    onEditorChange,
    filenamePrefix = 'pandium',
    fileType = 'text',
    upload,
}) => {
    const isDraft = serverText !== editedText

    const onDownloadClick = () => {
        const now = new Date()
        const filename = `${filenamePrefix}_${now.getFullYear()}_${now.getMonth()}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}`
        const blob = new Blob([editedText], {
            type: fileType,
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
    }

    return (
        <>
            {isUploading ? (
                <FileUploader
                    setEditedText={setEditedText}
                    setIsUploading={setIsUploading}
                />
            ) : (
                <AceEditor
                    mode={mode}
                    value={editedText}
                    theme="cobalt"
                    name={'csseditor'}
                    onChange={onEditorChange}
                    width={'100%'}
                    fontSize={12}
                    lineHeight={19}
                    showPrintMargin={false}
                />
            )}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginY: '10px',
                }}
            >
                <Box>
                    <Button
                        sx={{ mr: 3 }}
                        size={'large'}
                        variant={'outlined'}
                        onClick={() => setIsUploading(true)}
                    >
                        Upload
                    </Button>
                    <Button
                        variant={'outlined'}
                        size={'large'}
                        onClick={onDownloadClick}
                    >
                        Download
                    </Button>
                </Box>
                {isDraft && upload && (
                    <Typography variant={'subtitle2'} color={'#00ffff'}>
                        Draft
                    </Typography>
                )}
                <Box>
                    <Button
                        size={'large'}
                        sx={{ color: '#fc3b40' }}
                        disabled={!isDraft}
                        onClick={() => setEditedText(serverText)}
                    >
                        Reset
                    </Button>
                    {upload && (
                        <Button
                            sx={{ ml: 3 }}
                            text={editedText}
                            variant={'filled'}
                            className={'filledButton'}
                            disabled={!isDraft}
                            onClick={upload}
                        >
                            Save
                        </Button>
                    )}
                </Box>
            </Box>
        </>
    )
}
