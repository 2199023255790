import { Button } from '@mui/material'
import { Capitalize } from '@thefront/pandipackV2'
import React from 'react'
import { useUpdate, useRedirect, useNotify, useCreate } from 'react-admin'
import { get, isEmpty } from 'lodash-es'

const buttonStyles = {
    buttonDimensions: {
        width: '165px',
        height: '42px',
        margin: '0 5px 0 5px',
    },
}

export const NextButton = (props) => {
    const { next, tabIndex, children, disabled } = props
    return (
        <Button
            onClick={next}
            disabled={tabIndex >= children.length - 1 || disabled}
        >
            Next
        </Button>
    )
}

/**
 * Creates resource and moves to next tab. Takes shouldRedirect prop. If false, only moves to next tab.
 * @param props
 * @returns {*}
 * @constructor
 */
export const CreateButton = (props) => {
    const {
        resource,
        values,
        next,
        integrationReleases,
        shouldRedirect,
        disabled,
        text = 'CREATE',
    } = props
    const notify = useNotify()
    const redirect = useRedirect()

    const isSubmitting = text.includes('SUBMIT')

    let combinedValues = { ...values }
    if (resource === 'tenants') {
        // If release has been selected, setup combinedValues
        if (
            values.integrationRelease &&
            get(integrationReleases, values.integrationRelease)
        ) {
            combinedValues = {
                ...combinedValues,
                name: `${
                    get(integrationReleases, values.integrationRelease)[
                        'integrationName'
                    ]
                }--${values.name}`,
                source: 'admin',
            }
        }
    } else if (resource === 'integrations') {
        combinedValues = {
            ...combinedValues,
            repositoryUrl: values?.repositoryUrl?.trim(),
        }
    }
    // } else if (resource === 'integrationlabels' || resource === 'partners') {
    //     combinedValues = { ...combinedValues }
    // }

    if (resource === 'integrations' && isSubmitting) {
        combinedValues = {
            ...combinedValues,
            approval_status: 'Submitted',
        }
    }

    const [create, { isLoading }] = useCreate()

    const handleClick = () => {
        create(
            resource,
            { data: combinedValues },
            {
                onSuccess: (data) => {
                    console.debug('Success: ', data)
                    if (shouldRedirect) {
                        redirect(`/${resource}/${get(data, 'id')}/show`)
                    } else {
                        next(data)
                    }
                    notify(`${Capitalize(resource)} Created!`)
                },
                onError: (error) => {
                    notify(`Error Creating: ${get(error, 'message', error)}`)
                    console.debug('Error: ', get(error, 'message', error))
                },
            }
        )
    }

    return (
        <Button
            variant={isSubmitting ? 'outlined' : 'text'}
            className={!isSubmitting ? 'filledButton' : ''}
            sx={buttonStyles.buttonDimensions}
            onClick={handleClick}
            disabled={isLoading || disabled}
        >
            {text}
        </Button>
    )
}

/**
 * Updates resource and redirects to current resources detail view.
 * Both integration and tenant create flows end with an update.
 * @param props
 * @returns {*}
 * @constructor
 */
export const UpdateButton = ({
    resource,
    values,
    jsonFormValues,
    record,
    integrationReleases,
    disabled,
    redirectOnSuccess = true,
    text = 'SAVE',
    successMessage = null,
    setUpdatedTenant = null,
}) => {
    const notify = useNotify()
    const redirect = useRedirect()

    let combinedValues = { ...values }

    if (resource === 'tenants') {
        // If release has been selected, setup combinedValues
        if (
            values.integrationRelease &&
            get(integrationReleases, values.integrationRelease)
        ) {
            combinedValues = {
                ...values,
            }
        }
        combinedValues = {
            ...combinedValues,
            configs: jsonFormValues,
        }
    }

    if (resource === 'integrations') {
        // avoiding empty null rows in syncSchedules
        if (!isEmpty(get(values, 'syncScheduleTimes', []))) {
            const schedules = get(values, 'syncScheduleTimes').filter(
                (schedule) => schedule
            )
            values['syncScheduleTimes'] = schedules
            combinedValues = {
                ...values,
                // remove white space from the end of this URL
                repositoryUrl: values.repositoryUrl?.trim(),
            }
        }
    }

    const [update, { isLoading }] = useUpdate(
        resource,
        {
            id: record.id,
            data: {
                ...combinedValues,
            },
            previousData: record,
        },
        {
            onSuccess: (data) => {
                console.debug('Success: ', data)
                if (redirectOnSuccess) {
                    redirect(`/${resource}/${record.id}/show`)
                } else {
                    if (setUpdatedTenant) {
                        setUpdatedTenant(data)
                    }
                }
                if (successMessage) {
                    notify(`${successMessage} Updated!`)
                } else {
                    notify(`${Capitalize(resource)} Updated!`)
                }
            },
            onError: (error) => {
                notify(`Error Creating: ${get(error, 'message', error)}`)
                console.debug('Error: ', get(error, 'message', error))
            },
        }
    )

    const isOutlinedClearStyle =
        text.includes('SUBMIT') || text.includes('APPROVE')
    return (
        <Button
            variant={isOutlinedClearStyle ? 'outlined' : 'text'}
            className={!isOutlinedClearStyle && 'filledButton'}
            sx={{
                ...buttonStyles.buttonDimensions,
                '&:hover': {
                    backgroundColor: '#353DDF',
                },
            }}
            disabled={isLoading || disabled}
            onClick={() => update()}
        >
            {' '}
            {text}{' '}
        </Button>
    )
}

export const DoneButton = (props) => {
    const { resource, values } = props
    const redirect = useRedirect()
    const classes = buttonStyles()

    return (
        <Button
            className={classes.filledButton}
            onClick={() => {
                redirect('/' + resource + '/' + values.id + '/show')
            }}
        >
            DONE
        </Button>
    )
}
